import './index.css'
import { useState } from 'react'
import Toggle from 'react-toggle'
import constants, { events } from '../../../constants'
import locale from '../../../locale'
import useStore from '../../../store'
import { log } from '../../../utils'

const Decks = ({ socket }) => {
  const [url, setUrl] = useState('')
  const [name, setName] = useState('')
  const isOpenDecksMode = useStore(state => state.isOpenDecksMode)
  const [_isOpenDecksMode, _setIsOpenDecksMode] = useState(isOpenDecksMode)
  const activeVJ = useStore(state => state.activeVJ)
  const hasEngaged = useStore(state => state.hasEngaged)

  const handleUrlChange = e => setUrl(e.target.value)
  const handleNameChange = e => setName(e.target.value)

  const handleUrlSubmit = e => {
    e.preventDefault()
    socket.emit('client:cue', url)
    log('emit:cue', url)
    setUrl('')
  }

  const handleNameSubmit = e => {
    e.preventDefault()
    if (!name) return

    setName('')
    socket.emit(events.client.submitVJ, name)
    log('emit - client:submitVJ', name)
  }

  const handleLeaveClick = e => {
    e.preventDefault()
    socket.emit(events.client.leaveDecks, name)
  }

  const handleOpenDecksModeToggle = () => {
    const isEnabled = !_isOpenDecksMode
    _setIsOpenDecksMode(isEnabled)
    socket.emit(events.client.setOpenDecksMode, isEnabled)
  }

  if (!hasEngaged) return null

  return (
    <>
      <div className='Decks'>
        {!activeVJ.name && !activeVJ.isSelf && <>
          <form onSubmit={handleNameSubmit}>
            <div className='Decks-input-container'>
              <input
                placeholder={locale.global.VJName}
                type='text' className='Decks-input'
                value={name} onChange={handleNameChange}
                maxLength={constants.VJ_NAME_MAX_LENGTH}
              />
              <button type='submit' className='Decks-button'>{locale.global.takeDecks}</button>
            </div>
          </form>
        </>}
        {(activeVJ.isSelf || isOpenDecksMode) && <>
          <form onSubmit={handleUrlSubmit}>
            <div className='Decks-input-container'>
              <input
                placeholder={locale.global.youtubeUrl}
                type='text'
                className='Decks-input'
                value={url}
                onChange={handleUrlChange}
              />
              <button type='submit' className='Decks-button'>{locale.global.cue}</button>
            </div>
          </form>
          {activeVJ.isSelf && <div className="Decks-controls">
            <div>
              <button className='enter-button leave-decks-button' onClick={handleLeaveClick}>{locale.global.leaveDecks}</button>
            </div>
            <div className="Decks-controls-open-decks">
                <div className="Decks-toggle-label">{locale.global.openDecksMode}</div>
                <Toggle icons={false} onChange={handleOpenDecksModeToggle} checked={_isOpenDecksMode} />
            </div>
          </div>}
        </>}
      </div>
    </>

  )
}

export default Decks
