import axios from 'axios'
import { constantCase } from 'change-case'

const DEBUG = false

export const isDev = () => process.env.NODE_ENV !== 'production'

export const getOriginUrl = () => (isDev()
  ? process.env.REACT_APP_BACKEND_URL_DEV
  : process.env.REACT_APP_BACKEND_URL_PROD) || window.location.origin

export const loadYoutubeIframeAPI = () => {
  const tag = document.createElement('script')
  tag.src = 'https://youtube.com/iframe_api'
  tag.id = 'youtubeScript'
  const [firstScriptTag] = document.getElementsByTagName('script')
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

export const log = (...args) => {
  if (DEBUG) {
    console.log(...args)
  } else {
    if (isDev()) {
      console.log(...args)
    }
  }
}

export const createActions = (set, actionsArray) => {
  const createAction = (actionName, actionMutator) => {
    return {
      [actionName]: payload => {
        return set(state => {
          return {
            ...state,
            ...actionMutator(payload, state)
          }
        }, false, constantCase(actionName))
      }
    }
  }

  return actionsArray.reduce((acc, [actionName, actionMutator]) => ({
    ...acc,
    ...createAction(actionName, actionMutator)
  }), {})
}

export const getSecondsPassed = startedAt => {
  const now = Date.now() / 1000
  const start = startedAt / 1000
  const passed = now - start
  const passedFloor = Math.floor(passed)
  log('getSecondsPassed', {
    now,
    passed,
    passedFloor,
    start
  })
  return passedFloor >= 0 ? passedFloor : 0
}

export const createYoutubePlayer = (currentVideo = {}) => {
  const { duration, startedAt, videoId } = currentVideo
  const startAt = getSecondsPassed(startedAt)

  log(`init: starting video ${videoId} at ${startAt} seconds in`, { currentVideo })

  const player = new window.YT.Player('ytplayer', {
    events: {
      onReady: e => {
        log('ready')
        window.player.unMute()
        window.player.setVolume(100)
      }
      // onStateChange: onPlayerStateChange
    },
    playerVars: {
      autoplay: 1,
      controls: 1,
      enablejsapi: 1,
      fs: 1,
      iv_load_policy: 3,
      modestbranding: 1,
      mute: 1,
      origin: getOriginUrl(),
      playsinline: 1,
      rel: 0,
      showinfo: 0,
      ...(startAt && startAt < duration && {
        start: startAt
      })
    },
    ...(videoId && {
      videoId
    }),
    width: '562'
  })

  return player
}

export const waitSeconds = seconds => new Promise(resolve => {
  setTimeout(() => {
    resolve()
  }, seconds * 1000)
})

const getPlayerElement = () => document.querySelector('#ytplayer')

export const youtubePlayerIsReady = () => {
  return window.YT && window.player && getPlayerElement()
}

export const ensureYoutubeIframeAPI = async currentVideo => {
  log('ensureYoutubeIframeAPI')
  if (youtubePlayerIsReady()) return window.player

  if (window.YT && getPlayerElement()) {
    await waitSeconds(1)
    window.player = createYoutubePlayer(currentVideo)
    if (youtubePlayerIsReady()) return window.player
    return ensureYoutubeIframeAPI()
  } else {
    await waitSeconds(1)
    return ensureYoutubeIframeAPI()
  }
}

export const getClassName = (...args) => {
  return args.filter(Boolean).join(' ').trim()
}

export const getHandleUrl = name => {
  if (name[0] !== '@' || name.match(/(\s)/g)) return false
  if (name.match(/(\.)/g)) return `https://www.instagram.com/${name.slice(1)}`
  return `https://twitter.com/${name.slice(1)}`
}

export const removeFromPool = async videoId => {
  const { secret } = window.app
  videoId = videoId || window.app.getCurrentVideo()

  if (!secret || !videoId) return false

  const origin = getOriginUrl()

  return axios.post(`${origin}/api/video/${videoId}/remove`, { secret })
    .then(console.log)
    .catch(console.error)
}

export const setTabTitle = (videoTitle) => {
  document.title = !videoTitle ? 'mix' : `${videoTitle} | mix`
}

export default {
  createActions,
  getClassName,
  getHandleUrl,
  getOriginUrl,
  getSecondsPassed,
  isDev,
  loadYoutubeIframeAPI,
  log,
  removeFromPool,
  setTabTitle
}
