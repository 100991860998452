import constants from './constants'

export const languages = {
  en: {
    global: {
      VJName: 'enter your VJ name here',
      cue: 'cue',
      decks: 'decks',
      decksTooltip: 'Click here to start VJing',
      enter: 'start watching',
      leaveDecks: 'quit',
      listenerCount: 'Number of people watching',
      openDecksMode: '[open mode]',
      openDecksModeTooltip: 'The current VJ has opened the decks for everyone to cue videos, click on "decks" to start cueing videos',
      poolMode: '[pool mode]',
      poolModeTooltip: 'The current video was not cued by a VJ, but selected from the video pool',
      queue: 'queue',
      queueCount: 'Number of videos in queue (click to display queue)',
      skip: 'Skip current video',
      takeDecks: 'take decks',
      watch: 'watch',
      watchTooltip: 'Click here to watch the VJ set',
      youtubeUrl: 'youtube ID or URL'
    }
  }
}

const locale = languages[constants.LANGUAGE]

export default locale
