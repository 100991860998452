export const roles = {
  VISITOR: 'VISITOR',
  VJ: 'VJ'
}

export const events = {
  client: {
    cue: 'client:cue',
    getState: 'client:getState',
    leaveDecks: 'client:leaveDecks',
    setOpenDecksMode: 'client:setOpenDecksMode',
    skip: 'client:skip',
    submitVJ: 'client:submitVJ'
  },
  server: {
    requestState: 'server:requestState',
    state: 'server:state'
  }
}

export const colors = {
  black: '#000000',
  blue: '#1446A0',
  cyan: '#007e80',
  darkGray: '#434343',
  green: '#40ea76',
  purple: '#7614a0',
  red: '#C00000',
  white: '#FFFFFF',
  yellow: '#F5D547'
}

const LANGUAGE = 'en'
const VJ_NAME_MAX_LENGTH = 16

export default {
  LANGUAGE,
  VJ_NAME_MAX_LENGTH,
  events,
  roles
}
