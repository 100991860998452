import './index.css'
import { Link, useLocation } from 'react-router-dom'
import { colors } from '../../constants'
import locale from '../../locale'
import useStore from '../../store'
import { getClassName, getHandleUrl } from '../../utils'

const Menu = () => {
  const location = useLocation()
  const activeVJ = useStore(state => state.activeVJ)
  const setHasEngaged = useStore(state => state.setHasEngaged)
  const hasEngaged = useStore(state => state.hasEngaged)
  const currentVideo = useStore(state => state.currentVideo)
  const isOpenDecksMode = useStore(state => state.isOpenDecksMode)

  const hasActiveVJ = activeVJ.name
  const decksAvailable = !hasActiveVJ || activeVJ.isSelf
  const VJHandleUrl = getHandleUrl(activeVJ.name)

  const handleDecksClick = e => !decksAvailable && !isOpenDecksMode && e.preventDefault()
  const handleNameClick = e => !VJHandleUrl && e.preventDefault()
  const handleEngageClick = () => setHasEngaged(true)
  const getVJName = () => {
    if (isOpenDecksMode) {
      return {
        alt: locale.global.openDecksModeTooltip,
        color: colors.green,
        text: locale.global.openDecksMode
      }
    }
    if (currentVideo.fromPool && activeVJ.name) {
      return {
        alt: `${activeVJ.name} is currently VJing`,
        color: colors.blue,
        text: activeVJ.name
      }
    }
    if (currentVideo.fromPool && !activeVJ.name) {
      return {
        alt: locale.global.poolModeTooltip,
        color: colors.red,
        fontWeight: 'normal',
        text: locale.global.poolMode
      }
    }
    return {
      alt: `${activeVJ.name} is currently VJing`,
      color: colors.blue,
      text: activeVJ.name || ''
    }
  }

  const VJ = getVJName()

  return (
    <header>
      <div className='Menu' style={{
        ...(!hasEngaged && {
          height: '100vh'
        })
      }}>
        {hasEngaged && <>
          <a
            href={VJHandleUrl || '#'}
            className={getClassName('vj-name')}
            target='_blank'
            onClick={handleNameClick}
            rel="noreferrer"
            style={{
              color: VJ.color,
              fontWeight: VJ.fontWeight || 'bold'
            }}
            alt={VJ.alt}
            title={VJ.alt}
          >
            {VJ.text}
          </a>
          {(isOpenDecksMode || decksAvailable || activeVJ.isSelf) && <Link
            to='/'
            className={getClassName('watch', location.pathname === '/' && 'current')}
            alt={locale.global.watchTooltip}
            title={locale.global.watchTooltip}
          >
            {locale.global.watch}
          </Link>}
          {(isOpenDecksMode || decksAvailable || activeVJ.isSelf) && (
            <Link
              to='/decks'
              className={getClassName('decks', location.pathname === '/decks' && 'current')}
              onClick={handleDecksClick}
              alt={locale.global.decksTooltip}
              title={locale.global.decksTooltip}
            >
              {locale.global.decks}
            </Link>
          )}
        </>}
        {!hasEngaged && <button onClick={handleEngageClick} className='enter-button'>{locale.global.enter}</button>}
      </div>
    </header>
  )
}

export default Menu
