import './index.css'
import { useLocation } from 'react-router-dom'

const Player = ({ hasEngaged }) => {
  const location = useLocation()
  const isDecks = location.pathname === '/decks'
  const containerClass = isDecks
    ? 'ytplayer-container ytplayer-small'
    : 'ytplayer-container'

  return (
    <>
      <div className={containerClass} style={{
        display: hasEngaged ? 'block' : 'none'
      }}>
        <div id='ytplayer'/>
      </div>
    </>

  )
}

export default Player
