import './index.css'
import { useState } from 'react'
import locale from '../../locale'
import useStore from '../../store'

const DrawerItem = props => {
  const { i, video } = props
  const url = `https://www.youtube.com/watch?v=${video.videoId}`

  return (
    <div className="DrawerItem">
      <div className="index">{i === 0 ? 'NP' : i}</div>
      <a
        className="drawerLink"
        target="_blank"
        href={url}
        style={{
          color: video.fromPool ? '#C00000' : '#FFFFFF'
        }}
        rel="noreferrer"
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigator.clipboard.writeText(url)
        }}
      >
        {video.title}
      </a>
    </div>
  )
}

const Drawer = props => {
  const queueList = ([
    {
      fromPool: props.currentVideo.fromPool,
      title: props.currentVideo.title,
      videoId: props.currentVideo.videoId
    },
    ...props.queue
  ]).map((video, i) => (
    <DrawerItem key={video.videoId + 'drawer' + Math.random()} video={video} i={i}/>
  ))

  return (
    <>
      <div
        className="queueCount"
        title={locale.global.queueCount}
        alt={locale.global.queueCount}
      >
        {props.queueCount}
      </div>
      <div className="Drawer">
        <div className="heading">{locale.global.queue}</div>
        <div className="scrollable">
          {queueList}
        </div>
      </div>
    </>
  )
}

const QueueCount = () => {
  const queueCount = useStore(state => state.queueCount)
  const queue = useStore(state => state.queue)
  const currentVideo = useStore(state => state.currentVideo)
  const activeVJ = useStore(state => state.activeVJ)
  const isOpenDecksMode = useStore(state => state.isOpenDecksMode)
  const [pulledOut, setPulledOut] = useState(false)
  const shouldDisplaySkipButton = !activeVJ.name || (activeVJ.isSelf && !isOpenDecksMode) || isOpenDecksMode

  const handleClick = (e) => {
    if (e.target.className.includes('drawerLink')) return
    setPulledOut(!pulledOut)
  }

  return (
    <div
      className={`ListenerCount QueueCount${!shouldDisplaySkipButton && pulledOut ? ' long' : ''}`}
      style={{
        ...(!shouldDisplaySkipButton && {
          borderBottom: '1px solid #ffffff'
        })
      }}
      onClick={handleClick}
    >
      {pulledOut
        ? <Drawer queue={queue} queueCount={queueCount} currentVideo={currentVideo}/>
        : (
          <div
            className="queueCount"
            title={locale.global.queueCount}
            alt={locale.global.queueCount}
          >
            {queueCount}
          </div>
          )
      }
    </div>
  )
}

export default QueueCount
