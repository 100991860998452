import { Route, Routes } from 'react-router-dom'
import Decks from './Decks'
import Main from './Main'
import Menu from '../../components/Menu'
import useStore from '../../store'
import Player from '../Player'

const Pages = ({ children, socket }) => {
  const activeVJ = useStore(state => state.activeVJ)
  const hasEngaged = useStore(state => state.hasEngaged)

  return (
    <>
      <Menu socket={socket} activeVJ={activeVJ}/>
      <main>
        <Player hasEngaged={hasEngaged}/>
        <Routes>
          <Route path='/' element={<Main socket={socket} activeVJ={activeVJ}/>} />
          <Route path='/decks' element={<Decks socket={socket} activeVJ={activeVJ}/>} />
        </Routes>
      </main>
    </>
  )
}

export default Pages
