import './index.css'
import locale from '../../locale'
import useStore from '../../store'

const ListenerCount = () => {
  const users = useStore(state => state.users)

  return (
    <div
      className="ListenerCount listeners"
      title={locale.global.listenerCount}
      alt={locale.global.listenerCount}
    >
      {users} viewers
    </div>
  )
}

export default ListenerCount
