import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { createActions, isDev } from './utils'

const initialState = {
  activeVJ: {
    isSelf: false,
    name: ''
  },
  cuedFromPoolCount: 0,
  currentVideo: {
    duration: 0,
    startedAt: 0,
    title: '',
    updatedAt: 0,
    videoId: ''
  },
  hasEngaged: false,
  isOpenDecksMode: false,
  queue: [],
  showEmbed: false,
  users: 1
}

const actions = [
  ['setActiveVJ', ({ isSelf, name }, state) => ({
    ...state,
    activeVJ: {
      isSelf,
      name
    }
  })],
  ['setCurrentVideo', ({ duration, fromPool, startedAt, title, updatedAt, videoId }, state) => ({
    ...state,
    currentVideo: {
      duration,
      fromPool,
      startedAt,
      title,
      updatedAt,
      videoId
    }
  })],
  ['setShowEmbed', (showEmbed, state) => ({
    ...state,
    showEmbed
  })],
  ['setHasEngaged', (hasEngaged, state) => ({
    ...state,
    hasEngaged
  })],
  ['setCuedFromPoolCount', (count, state) => ({
    ...state,
    cuedFromPoolCount: count
  })],
  ['setOpenDecksMode', (isOpenDecksMode, state) => ({
    ...state,
    isOpenDecksMode
  })],
  ['setUserCount', (users, state) => ({
    ...state,
    users
  })],
  ['setQueueCount', (queueCount, state) => ({
    ...state,
    queueCount
  })],
  ['setQueue', (queue, state) => ({
    ...state,
    queue
  })]
]

const store = set => ({ ...createActions(set, actions), ...initialState })
const persistedStore = store || persist(store, { name: 'yt-vj' })
const loggedStore = devtools(persistedStore)
const useStore = create(isDev() ? loggedStore : persistedStore)

window.app = {
  ...window.app,
  getCurrentVideo: () => {
    const state = useStore.getState()
    return state.currentVideo && state.currentVideo.videoId
  }
}

export default useStore
