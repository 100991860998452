import React from 'react'
import ReactDOM from 'react-dom'
import './vendor/styles/toggle.css'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import io from 'socket.io-client'
import App from './components/App'
import { getOriginUrl, loadYoutubeIframeAPI, removeFromPool } from './utils'

const originUrl = getOriginUrl()

const socket = io.connect(originUrl, {
  rejectUnauthorized: false
})

loadYoutubeIframeAPI()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App socket={socket}/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

window.app = {
  ...window.app,
  removeFromPool
}
