import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { events } from '../../constants'
import useStore from '../../store'
import { ensureYoutubeIframeAPI, getSecondsPassed, log, setTabTitle, youtubePlayerIsReady } from '../../utils'
import ListenerCount from '../ListenerCount'
import Pages from '../Pages'
import QueueCount from '../QueueCount'
import SkipButton from '../SkipButton'

const getNextVideoTitle = (callback) => {
  const initial = window.player.videoTitle
  let current = initial

  const interval = setInterval(() => {
    current = window.player.videoTitle
    if (current && current !== initial) {
      callback(current)
      clearInterval(interval)
    }
  }, 100)
}

const App = ({ socket }) => {
  const navigate = useNavigate()
  const currentVideo = useStore(state => state.currentVideo)
  const setActiveVJ = useStore(state => state.setActiveVJ)
  const setCurrentVideo = useStore(state => state.setCurrentVideo)
  const setCuedFromPoolCount = useStore(state => state.setCuedFromPoolCount)
  const hasEngaged = useStore(state => state.hasEngaged)
  const setOpenDecksMode = useStore(state => state.setOpenDecksMode)
  const setUserCount = useStore(state => state.setUserCount)
  const setQueueCount = useStore(state => state.setQueueCount)
  const setQueue = useStore(state => state.setQueue)

  useEffect(() => {
    if (youtubePlayerIsReady()) {
      ensureYoutubeIframeAPI().then(player => {
        const startAt = getSecondsPassed(currentVideo.startedAt)

        log(`cue: starting video ${currentVideo.videoId} at ${startAt} seconds in`, { currentVideo })

        window.player.loadVideoById(currentVideo.videoId, startAt)

        getNextVideoTitle(title => {
          setTabTitle(title)
        })
      })
    }
  }, [currentVideo.videoId, currentVideo.startedAt])

  useEffect(() => {
    socket.on(events.server.requestState, () => {
      log('recieved - server:requestState')
      socket.emit(events.client.getState)
    })

    socket.on(events.server.state, safeState => {
      log('recieved - server:state', { safeState })
      setActiveVJ(safeState.activeVJ)
      setCurrentVideo(safeState.currentVideo)
      setCuedFromPoolCount(safeState.cuedFromPoolCount)
      setOpenDecksMode(safeState.isOpenDecksMode)
      setUserCount(safeState.users)
      setQueueCount(safeState.queueCount)
      setQueue(safeState.queue)

      if (safeState?.currentVideo?.title) setTabTitle(safeState.currentVideo.title)

      if (!safeState.activeVJ.isSelf && !safeState.isOpenDecksMode) {
        navigate('/')
      }
    })
  }, [])

  useEffect(() => {
    if (hasEngaged) {
      // start initial video
      ensureYoutubeIframeAPI(currentVideo)
    }
  }, [hasEngaged])

  return (
    <div className='App'>
      <ListenerCount/>
      <QueueCount/>
      <SkipButton socket={socket}/>
      <Pages socket={socket}/>
    </div>
  )
}

export default App
