import './index.css'
import { faForwardFast } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { events } from '../../constants'
import locale from '../../locale'
import useStore from '../../store'

const SkipButton = ({ socket }) => {
  const activeVJ = useStore(state => state.activeVJ)
  const isOpenDecksMode = useStore(state => state.isOpenDecksMode)
  const shouldDisplay = !activeVJ.name || (activeVJ.isSelf && !isOpenDecksMode) || isOpenDecksMode

  const handleClick = () => shouldDisplay && socket.emit(events.client.skip)

  if (!shouldDisplay) return null

  return (
    <div className="ListenerCount SkipButton" onClick={handleClick} title={locale.global.skip} alt={locale.global.skip}>
      <FontAwesomeIcon icon={faForwardFast} fontSize="16pt"/>
    </div>
  )
}

export default SkipButton
